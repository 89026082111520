<template>
  <div>
    <div class="text-subtitle-2 pa-1 blue--text blue lighten-5 mb-2">
      新着情報は、投稿日時順で最新4件までがホームページに表示されます。尚、20件目以降のデータは定期的に削除されます。
    </div>
    <app-bar
      ref="refAppBar"
      :shop_site_id="props.shop_site_id"
      @refresh="refresh"
    ></app-bar>
    <v-card elevation="0">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="（キーワード検索）"
          single-line
          hide-details
          clearable
          dense
        ></v-text-field>
      </v-card-title>
      <!-- テーブル -->
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="id"
        :mobile-breakpoint="0"
        :items-per-page="50"
        :footer-props="{
          'items-per-page-options': [50, 100, -1],
          'items-per-page-text': '表示件数',
        }"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:footer.page-text="props">
          {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
            props.itemsLength
          }}件
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <v-icon class="pa-1" @click="editItem(item)"> mdi-pencil </v-icon>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.path="{ item }">
          <div class="pa-1">
            <v-img
              width="50"
              height="50"
              :src="setting.cdn + item.path"
              v-if="item.path"
            ></v-img>
            <v-img
              width="50"
              height="50"
              v-else
              src="@/assets/images/no_image.jpg"
            ></v-img>
          </div>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.copy="{ item }">
          <v-icon class="pa-1" @click="copyItem(item)">
            mdi-content-copy
          </v-icon>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.delete="{ item }">
          <v-icon class="pa-1" @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:no-data> 表示するデータがありません </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  toRefs,
  defineComponent,
  onMounted,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import appBar from "./appBar.vue";
import utilDate from "@/common/utilDate.js";

export default defineComponent({
  components: {
    appBar,
  },
  props: ["shop_site_id"],
  setup(props, ctx) {
    const ShopSiteWhatsNewsRepository =
      repositoryFactory.get("shopSiteWhatsNews");
    const refAppBar = ref();
    const state = reactive({
      search: "",
      headers: [
        {
          text: "編集",
          value: "edit",
          sortable: false,
          align: "center",
          width: 60,
        },
        {
          text: "投稿日時",
          value: "post_date_format",
          sortable: true,
          width: 180,
        },
        { text: "画像", value: "path", sortable: false, width: 66 },
        {
          text: "投稿タイトル",
          value: "title",
          sortable: true,
          class: "td_title",
        },
        {
          text: "複製",
          value: "copy",
          sortable: false,
          align: "center",
          width: 60,
        },
        {
          text: "削除",
          value: "delete",
          sortable: false,
          align: "center",
          width: 60,
        },
      ],
      desserts: [], // DataTablesレコード格納用
    });

    const editItem = (whatsNew) => {
      // ダイアログ開く
      refAppBar.value.action({
        action: "edit",
        id: whatsNew.id,
        shop_site_id: props.shop_site_id,
      });
    };

    const copyItem = async (whatsNew) => {
      if (window.confirm("新着情報を複製してよろしいですか？")) {
        // コピー処理
        store.dispatch("loadingIcon/showIcon"); // ローディング表示
        const params = {
          id: whatsNew.id,
        };
        await ShopSiteWhatsNewsRepository.copy(params)
          .then((response) => {
            if (response.data) {
              // データ追加OK
            }
          })
          .catch((error) => {
            throw "ERROR:ShopSiteWhatsNewsRepository.copy (" + error + ")";
          });
        await init();
        store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
      }
    };

    // データ削除
    const deleteItem = async (item) => {
      if (window.confirm("削除してよろしいですか？")) {
        await ShopSiteWhatsNewsRepository.delete(item.id)
          .then((response) => {
            if (response.data) {
              // データ削除OK
            }
          })
          .catch((error) => {
            throw "ERROR:ShopSiteWhatsNewsRepository.delete (" + error + ")";
          });
        await init();
      }
    };

    // 初期化処理
    const init = async () => {
      store.dispatch("loadingIcon/showIcon"); // ローディング表示

      // データ取得
      await ShopSiteWhatsNewsRepository.list_shopsite(props.shop_site_id)
        .then((response) => {
          if (response.data) {
            // データ取得OK
            Object.keys(response.data).forEach((key) => {
              response.data[key].post_date_format =
                utilDate.getDatetimeFormatLongDisplay(
                  utilDate.convertSplitStrToDate(response.data[key].post_date)
                );
            });
            state.desserts = response.data;
          }
        })
        .catch((error) => {
          throw (
            "ERROR:ShopSiteWhatsNewsRepository.list_shopsite (" + error + ")"
          );
        });

      store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };

    onMounted(async () => {
      // 初期化処理
      await init();
    });

    const refresh = async () => {
      // 初期化処理
      await init();
    };

    // 返却オブジェクト定義
    return {
      refAppBar,
      setting,
      utilDate,
      props,
      ...toRefs(state),
      editItem,
      copyItem,
      deleteItem,
      init,
      refresh,
    };
  },
});
</script>
<style scoped>
::v-deep .v-data-table table thead tr th,
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .td_template_name {
  min-width: 200px;
}
::v-deep .td_content_format_name {
  min-width: 80px;
  width: 120px;
  font-size: 0.75em;
}
</style>
