var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-subtitle-2 pa-1 blue--text blue lighten-5 mb-2"},[_vm._v(" トップに表示するピックアップキャストを選択してください。 ")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}}),_c('v-col',{attrs:{"cols":"6"}},[_c('div',[_c('v-text-field',{staticClass:"mb-2",attrs:{"append-icon":"mdi-magnify","label":"（キャスト名検索）","single-line":"","hide-details":"","clearable":"","dense":""},model:{value:(_vm.castSearch),callback:function ($$v) {_vm.castSearch=$$v},expression:"castSearch"}})],1)])],1)],1),_c('v-data-table',{attrs:{"id":"cast_table","headers":_vm.castHeaders,"items":_vm.casts,"search":_vm.castSearch,"item-key":"id","show-select":"","single-select":false,"mobile-breakpoint":0,"items-per-page":50,"footer-props":{
      'items-per-page-options': [50, 100, -1],
      'items-per-page-text': '表示件数',
    }},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" ～"+_vm._s(props.pageStop)+"件／全"+_vm._s(props.itemsLength)+"件 ")]}},{key:"item.image",fn:function(ref){
    var item = ref.item;
return [_c('v-avatar',{staticClass:"ma-1"},[_c('v-img',{attrs:{"src":_vm.setting.cdn + item.image}})],1)]}},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.enable",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"pa-1"},[(item.is_enable)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"primary","label":"","small":""}},[_vm._v(" 公開中 ")]):_c('v-chip',{staticClass:"ma-2",attrs:{"label":"","small":""}},[_vm._v(" 非公開 ")])],1)]}},{key:"item.profile",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"profile_span"},[_vm._v(_vm._s(item.profile_1)),_c('br'),_vm._v(_vm._s(item.profile_2))])]}},{key:"no-data",fn:function(){return [_vm._v(" キャストが登録されていません ")]},proxy:true}]),model:{value:(_vm.castSelected),callback:function ($$v) {_vm.castSelected=$$v},expression:"castSelected"}}),_c('v-container',[_c('v-row',{staticClass:"mt-3",attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('general-button',{attrs:{"btn_type":"info","btn_block":""},on:{"click-event":_vm.onSubmit},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")])]},proxy:true}])},[_vm._v("保存")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }