<template>
  <div>
    <div
      v-show="!hp_link_option_enable"
      class="text-subtitle-2 pa-1 orange--text orange lighten-5"
    >
      ご利用には「ホームページ連携」オプションの契約が必要です。
    </div>
    <div style="position: relative">
      <v-overlay
        :value="!hp_link_option_enable"
        absolute
        opacity="0.2"
        z-index="5"
      ></v-overlay>
      <template v-if="shopSites.length">
        <v-tabs v-model="refSiteTabs">
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="shopSite in shopSites"
            :key="shopSite.id"
            class="font-weight-bold"
            >{{ shopSite.name }}</v-tab
          >
        </v-tabs>
        <v-tabs-items v-model="refSiteTabs" touchless>
          <v-tab-item
            v-for="shopSite in shopSites"
            :key="shopSite.id"
            :eager="true"
          >
            <component
              v-bind:is="shopSite.component"
              ref="refShopSiteComponent"
              :com_data="shopSite"
            ></component>
          </v-tab-item>
        </v-tabs-items>
      </template>
    </div>
  </div>
</template>

<script>
import { ref, set, reactive, onMounted, toRefs } from "@vue/composition-api";
import { useRouter } from "@/utils";
import { repositoryFactory } from "@/repository/repositoryFactory";
import SiteComponent from "./component/site.vue";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate.js";
import utilFunc from "@/common/utilFunc.js";
import _ from "lodash";

export default {
  components: {
    SiteComponent,
  },
  setup(props, ctx) {
    const { router } = useRouter();
    const refShopSiteComponent = ref();
    const refSiteTabs = ref();
    const refresh = ref();

    const state = reactive({
      hp_link_option_enable: false,
      overlay: false,
      errorTab: null,
      errorCom: null,
      shopSites: [],
      casts: [],
      baseComponents: {},
    });

    const init = async () => {
      // 更新媒体で営業タイプの媒体を取得
      const tmpShopSite = store.getters["shop_sites/getShopSiteList"];
      for (let i = 0; i < tmpShopSite.length; i++) {
        if (
          tmpShopSite[i].sites_type == setting.site_type_sales &&
          tmpShopSite[i].is_active &&
          tmpShopSite[i].sites_category == setting.site_category_hp_link &&
          tmpShopSite[i].sites_com_name == "hp_link"
        ) {
          // 媒体毎のコンポーネントに渡すデータを生成
          const prefix =
            setting.cast_props_only +
            "__" +
            tmpShopSite[i].id +
            "__" +
            tmpShopSite[i].sites_com_name;

          // データを渡す
          state.shopSites.push({
            id: tmpShopSite[i].id,
            sites_id: tmpShopSite[i].sites_id,
            name: tmpShopSite[i].name,
            color: tmpShopSite[i].color,
            sites_com_name: tmpShopSite[i].sites_com_name,
            is_active: tmpShopSite[i].is_active,
            component: SiteComponent,
          });
        }
      }
    };

    init();

    // 初期化処理
    onMounted(async () => {
      // 店舗チェック falseの場合ログアウトになる
      const currentShop = store.getters["shops/currentShop"];
      const checkResult = await utilFunc.checkShop(currentShop.id, router);
      if (!checkResult) return;
      // 契約チェック
      if (
        (await utilFunc.getValidOptionCount(
          checkResult.valid_option,
          setting.plan_code_hp_link
        )) > 0
      ) {
        state.hp_link_option_enable = true;
      }
    });

    const tabMove = (tabIndex) => {
      refSiteTabs.value = tabIndex;
      refresh.value++;
    };

    const setOverlay = (overlay) => {
      state.overlay = overlay;
    };

    return {
      setting,
      refShopSiteComponent,
      refSiteTabs,
      tabMove,
      refresh,
      ...toRefs(state),
      setOverlay,
    };
  },
};
</script>
