<template>
  <div>
    <v-app-bar flat color="white">
      <div class="pa-2">
        <whats-new-dialog-component
          ref="refDialogComponent"
          :shop_site_id="props.shop_site_id"
          @refresh="refresh"
        ></whats-new-dialog-component>
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import whatsNewDialogComponent from "./component/whatsNewDialogComponent.vue";

export default {
  components: {
    whatsNewDialogComponent,
  },
  props: ["shop_site_id"],
  setup(props, ctx) {
    const refDialogComponent = ref(null);

    // app-barにあるコンポーネント呼び出し
    const action = (params) => {
      switch (params.action) {
        case "edit":
          refDialogComponent.value.editDialog(params.shop_site_id, params.id);
          break;
      }
    };

    const refresh = () => {
      ctx.emit("refresh");
    };

    return {
      props,
      refDialogComponent,
      action,
      refresh,
    };
  },
};
</script>
