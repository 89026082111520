<template>
  <!-- テンプレート登録ダイアログ -->
  <div>
    <v-btn class="info font-weight-bold" @click="showDialog"
      ><v-icon left>mdi-plus-box</v-icon
      ><span class="d-none d-sm-flex">新規登録</span
      ><span class="d-sm-none">新規</span></v-btn
    >
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      width="100%"
      max-width="1200px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">新着情報</span>
        </v-card-title>
        <v-card-text class="scrollable-content">
          <input type="hidden" v-model="item.id" />
          <ValidationObserver ref="observer">
            <h3 id="title_head">投稿タイトル</h3>
            <div class="mt-2 mb-2"></div>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="タイトル"
                    rules="required|max:255"
                  >
                    <v-text-field
                      v-model="item.title"
                      name="title"
                      :error-messages="errors"
                      :success="valid"
                      label="タイトル"
                      hint="タイトルを入力してください[必須項目]"
                      persistent-hint
                      outlined
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-container>
            <h3 id="body_head">投稿本文</h3>
            <div class="mt-2 mb-2">
              HTMLが使用できるコンテンツ用に入力してください。<v-btn
                text
                color="primary"
                class="font-weight-bold pa-1 d-inline"
                @click="onHtmlClear"
                >HTMLをクリア</v-btn
              >
            </div>
            <v-card tile elevation="0" class="mb-6">
              <div class="ma-3">
                <ckeditor-component
                  ref="refCkeditor"
                  :data="item.body"
                ></ckeditor-component>
              </div>
            </v-card>
            <h3 id="image_head">画像</h3>
            <div class="mt-2 mb-2 pa-2">
              <div class="image_card">
                <v-card loading="false" outlined tile width="400">
                  <v-img
                    height="400px"
                    contain
                    :src="
                      uploadImage.image_path
                        ? setting.cdn + uploadImage.image_path
                        : uploadImage.image_base64
                    "
                    v-if="uploadImage.image_path || uploadImage.image_base64"
                  >
                  </v-img>
                  <v-img
                    height="400px"
                    src="@/assets/images/no_image.jpg"
                    v-else
                  ></v-img>
                  <v-card-actions>
                    <input
                      name="upload_file"
                      ref="upload"
                      type="file"
                      style="display: none"
                      @change="onImageUploaded"
                    />
                    <v-btn icon>
                      <v-icon @click="$refs.upload.click()">mdi-upload</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn icon>
                      <v-icon @click="onImageDelete()">mdi-delete</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </div>
            </div>
            <h3>投稿日時</h3>
            <div class="mt-2 mb-2 pa-2">
              <v-container fluid>
                <v-row>
                  <v-col cols="6" sm="2">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="updateDate"
                          label="日付選択"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details="false"
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="updateDate"
                        no-title
                        @input="menu = false"
                        :first-day-of-week="0"
                        locale="ja-jp"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-form ref="refForm">
                      <div class="d-flex flex-wrap">
                        <div class="flex_center">
                          <div class="select_item">
                            <v-select
                              class="select_hour"
                              v-model="updateHour"
                              :items="selectITem.hourItem"
                              item-text="text"
                              item-value="value"
                              label="時"
                              dense
                              hide-details="false"
                              :rules="[required]"
                            >
                            </v-select>
                          </div>
                        </div>
                        <div class="flex_center">
                          <div class="select_item ml-1">
                            <v-select
                              class="select_minute"
                              v-model="updateMinute"
                              :items="selectITem.minuteItem"
                              item-text="text"
                              item-value="value"
                              label="分"
                              dense
                              hide-details="false"
                              :rules="[required]"
                            ></v-select>
                          </div>
                        </div>
                      </div>
                    </v-form>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </ValidationObserver>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
              <v-col cols="6" sm="3">
                <general-button
                  btn_type="info"
                  btn_block
                  @click-event="onSubmit"
                  ><template v-slot:icon
                    ><v-icon left>mdi-content-save</v-icon></template
                  >保存</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import draggable from "vuedraggable";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import selectITem from "@/common/selectItem.js";
import shopSiteWhatsNewsRepository from "@/repository/models/shopSiteWhatsNewsRepository";

export default defineComponent({
  components: {
    draggable,
  },
  props: ["shop_site_id"],
  setup(props, ctx) {
    const ShopSiteWhatsNewsRepository =
      repositoryFactory.get("shopSiteWhatsNews");
    const fileRepository = repositoryFactory.get("file");

    const refCkeditor = ref(null);
    const state = reactive({
      dialog: false, // ダイアログ表示用
      item: {
        id: 0,
        shop_site_id: null,
        post_date: null,
        title: "",
        body: "",
      },
      uploadImage: null,
      updateDate: null,
      updateHour: 0,
      updateMinute: 0,
    });

    // 新規作成
    const showDialog = async () => {
      await store.dispatch("loadingIcon/showIcon"); // ローディング表示

      scrollReset();

      state.item.shop_site_id = props.shop_site_id;

      // 投稿日時セット
      const d = new Date();
      set(
        state,
        "updateDate",
        d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate()
      );
      set(state, "updateHour", d.getHours());
      set(state, "updateMinute", d.getMinutes());

      // ダイアログ開く
      state.dialog = true;

      // ローディング非表示
      await store.dispatch("loadingIcon/hideIcon");
    };

    const clearDialog = async () => {
      // 各種項目初期化
      state.item.id = 0;
      state.item.shop_site_id = null;
      state.item.title = "";
      state.uploadImage = {
        "image_path": null,
        "image_base64": null,
        "image_base64_type": null,
      };
      state.item.body = "";
      if (refCkeditor.value) {
        refCkeditor.value.editorData = "";
      }
    };

    const editDialog = async (shop_site_id, whatsNewId) => {
      store.dispatch("loadingIcon/showIcon");

      await shopSiteWhatsNewsRepository
        .get(whatsNewId)
        .then((response) => {
          if (response.data) {
            // データ取得OK
            state.item.id = response.data.id;
            state.item.shop_site_id = response.data.shop_site_id;
            state.item.title = response.data.title;
            state.item.body = response.data.body;
            if (refCkeditor.value) {
              refCkeditor.value.editorData = response.data.body;
            }
            state.uploadImage = {
              "image_path": response.data.path,
              "image_base64": null,
              "image_base64_type": null,
            };
            state.updateDate = response.data.post_date.substr(0, 10);
            state.updateHour = parseInt(response.data.post_date.substr(11, 2));
            state.updateMinute = parseInt(
              response.data.post_date.substr(14, 2)
            );
          }
        })
        .catch((error) => {
          throw "ERROR:shopSiteWhatsNewsRepository.get (" + error + ")";
        });

      store.dispatch("loadingIcon/hideIcon");
      state.dialog = true;
    };

    const onImageDelete = () => {
      state.uploadImage = {
        "image_path": null,
        "image_base64": null,
        "image_base64_type": null,
      };
    };

    const createImage = async (image) => {
      const reader = new FileReader();
      // imageをreaderにDataURLとしてattachする
      reader.readAsDataURL(image);
      // readAdDataURLが完了したあと実行される処理
      reader.onload = () => {
        state.uploadImage = {
          "image_path": null,
          "image_base64": reader.result,
          "image_base64_type": image.type,
        };
      };
    };

    const onImageUploaded = (e) => {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      const type = files[0].type;
      if (
        type !== "image/jpeg" &&
        type !== "image/gif" &&
        type !== "image/png"
      ) {
        alert(".jpg、.gif、.pngのいずれかのファイルのみ許可されています");
        return;
      }
      createImage(files[0], e.target.getAttribute("upload_index"));
    };

    // 保存処理
    const onSubmit = async () => {
      // 入力チェック エラー状態の部品が無いか調べる
      const isValid = await ctx.refs.observer.validate();

      if (!isValid) {
        // エラー処理
        return;
      } else {
        if (!window.confirm("保存してよろしいですか？")) {
          return false;
        }
        // ローディング表示
        store.dispatch("loadingIcon/showIcon");
        // 保存処理
        let body_html = "";
        if (refCkeditor.value) {
          body_html = refCkeditor.value.editorData;
        } else {
          body_html = state.item.body;
        }
        if (state.uploadImage.image_base64) {
          // ここで画像保存処理(サーバにアップロードする)
          let ext = state.uploadImage.image_base64_type.substr(
            state.uploadImage.image_base64_type.indexOf("/") + 1
          );
          if (ext == "jpeg") {
            ext = "jpg";
          }
          const d = new Date();
          // アップロード用のファイル名を生成
          const file_name =
            "_tmp_" +
            store.getters["shops/currentShop"].id +
            "_" +
            d.getTime() +
            "." +
            ext;
          const image_params = {
            shop_id: store.getters["shops/currentShop"].id,
            base64_image: state.uploadImage.image_base64,
            file_name: file_name,
          };
          // ファイルアップロード
          const upload_result = await fileRepository
            .upload_whatsnew(image_params)
            .then((response) => {
              if (response.data) {
                if (response.data.status) {
                  // 画像追加OK
                  set(state, "uploadImage", {
                    "image_path": response.data.file_name,
                    "image_base64": null,
                    "image_base64_type": null,
                  });
                } else {
                  // 画像追加NG
                  alert(response.data.message);
                }
                return response.data.status;
              }
            })
            .catch((error) => {
              throw "ERROR:fileRepository.upload_whatsnew (" + error + ")";
            });
          // アップロードに失敗した場合、抜ける。
          if (!upload_result) return;
        }

        const params = {
          shop_site_id: state.item.shop_site_id,
          post_date:
            state.updateDate +
            " " +
            ("00" + state.updateHour).slice(-2) +
            ":" +
            ("00" + state.updateMinute).slice(-2) +
            ":00",
          title: state.item.title,
          body: body_html,
          path: state.uploadImage.image_path
            ? state.uploadImage.image_path
            : "",
        };
        console.log(params);

        // 新規か更新か
        if (state.item.id == 0) {
          // 新規
          await shopSiteWhatsNewsRepository
            .create(params)
            .then((response) => {
              if (response.data) {
                // データ追加OK
                state.item.id = response.data.id; // idをセット
              }
            })
            .catch((error) => {
              throw "ERROR:shopSiteWhatsNewsRepository.create (" + error + ")";
            });
        } else {
          params.id = state.item.id;
          // 更新
          await shopSiteWhatsNewsRepository
            .update(params)
            .then((response) => {
              if (response.data) {
                // データ追加OK
              }
            })
            .catch((error) => {
              throw "ERROR:shopSiteWhatsNewsRepository.update (" + error + ")";
            });
        }

        ctx.refs.observer.reset(); // 入力エラー表示クリア
        // ローディング非表示
        store.dispatch("loadingIcon/hideIcon");
        cancelDialog();
        ctx.emit("refresh");
      }
    };

    // ダイアログキャンセルボタン
    const cancelDialog = () => {
      state.dialog = false;
      ctx.refs.observer.reset(); // 入力エラー表示クリア
      scrollReset();
      clearDialog();
    };

    const cancelCastDialog = () => {
      state.castDialog = false;
    };

    const scrollReset = () => {
      setTimeout(() => {
        document.querySelector(".scrollable-content").scroll(0, 0);
      }, 200);
    };

    const onHtmlClear = () => {
      if (
        !window.confirm("HTMLに入力された内容をクリアします。よろしいですか？")
      ) {
        return;
      }
      set(state.item, "body_html", "");
      if (refCkeditor.value) {
        refCkeditor.value.editorData = "";
      }
    };

    const init = async () => {
      // 初期化
      await clearDialog();
    };

    init();

    // 返却オブジェクト定義
    return {
      setting,
      refCkeditor,
      selectITem,
      ...toRefs(state),
      editDialog,
      onSubmit,
      cancelDialog,
      cancelCastDialog,
      showDialog,
      scrollReset,
      onImageDelete,
      onImageUploaded,
      onHtmlClear,
      menu: false,
      required: (value) => value != null,
    };
  },
});
</script>

<style scoped>
::v-deep #color-selector .v-input__append-inner {
  margin-top: 13px;
}
.edit_area {
  cursor: pointer;
}
::v-deep .td_content_name {
  width: 160px;
}
::v-deep .td_site_content_name {
  min-width: 160px;
}
.image_flame {
  overflow: auto;
}
.image_box {
  display: flex;
}
.image_card {
  margin-right: 0.25em;
  position: relative;
}
.image_drag_btn {
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 2;
}
.select_item {
  width: 4em;
}
</style>
