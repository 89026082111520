<template>
  <div>
    <div class="pa-2 cyan">
      <span class="text-subtitle-1 font-weight-bold white--text"
        >ホームページ連携用のコンテンツ設定</span
      >
    </div>
    <v-tabs v-model="items" class="mb-3">
      <v-tabs-slider></v-tabs-slider>
      <v-tab v-for="tab in tabs" :key="tab.index" class="font-weight-bold">{{
        tab.name
      }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="items" touchless>
      <v-tab-item v-for="tab in tabs" :key="tab.index">
        <component
          v-bind:is="tab.component"
          @tabmove="tabMove"
          :shop_site_id="props.com_data.id"
        ></component>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { ref, reactive, onMounted, toRefs } from "@vue/composition-api";
import setting from "@/common/setting.js";
import PickupComponent from "./siteComponent/pickup.vue";
import WhatsNewComponent from "./siteComponent/whatsNew/index.vue";

export default {
  components: {
    PickupComponent,
    WhatsNewComponent,
  },
  props: ["com_data"],
  setup(props, ctx) {
    const items = ref();

    const tabs = [
      {
        index: 0,
        name: "ピックアップ",
        component: PickupComponent,
      },
      {
        index: 1,
        name: "新着情報",
        component: WhatsNewComponent,
      },
    ];

    const tabMove = (tabIndex) => {
      items.value = tabIndex;
    };

    return {
      setting,
      items,
      tabs,
      props,
      tabMove,
    };
  },
};
</script>

<style scoped>
.v-tab--active {
  background-color: #e8f5e9;
}
</style>
