var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-subtitle-2 pa-1 blue--text blue lighten-5 mb-2"},[_vm._v(" 新着情報は、投稿日時順で最新4件までがホームページに表示されます。尚、20件目以降のデータは定期的に削除されます。 ")]),_c('app-bar',{ref:"refAppBar",attrs:{"shop_site_id":_vm.props.shop_site_id},on:{"refresh":_vm.refresh}}),_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"（キーワード検索）","single-line":"","hide-details":"","clearable":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.desserts,"search":_vm.search,"item-key":"id","mobile-breakpoint":0,"items-per-page":50,"footer-props":{
        'items-per-page-options': [50, 100, -1],
        'items-per-page-text': '表示件数',
      }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" ～"+_vm._s(props.pageStop)+"件／全"+_vm._s(props.itemsLength)+"件 ")]}},{key:"item.edit",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"pa-1",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"item.path",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"pa-1"},[(item.path)?_c('v-img',{attrs:{"width":"50","height":"50","src":_vm.setting.cdn + item.path}}):_c('v-img',{attrs:{"width":"50","height":"50","src":require("@/assets/images/no_image.jpg")}})],1)]}},{key:"item.copy",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"pa-1",on:{"click":function($event){return _vm.copyItem(item)}}},[_vm._v(" mdi-content-copy ")])]}},{key:"item.delete",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"pa-1",on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_vm._v(" 表示するデータがありません ")]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }